.footer-wrapper {
  padding: 5px;
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  height: 100px;
  margin-top: -100px;
}

.footer-content {
  display: flex;
  background: rgba(136, 231, 200, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 0.28571429rem;
  padding: 5px 45px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-around;
}

.links-text {
  color: #006363;
  font-weight: bold;
}

.requisites-footer {
  margin-top: 5px;
  color: #006363;
  font-size: 0.7em;
  text-align: center;
}

.links-text:hover {
  cursor: pointer;
}

.payment-methods {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: flex-end;
}

.payment-methods-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  max-width: 60%;
}

.payment-methods-text {
  color: #006363;
  font-weight: bold;
  font-size: 1.2rem;
}

.images-content {
  display: flex;
  align-items: center;
}

.image-footer {
  height: 30px;
}

@media (max-width: 810px) {
  .payment-methods-content {
    max-width: 90%;
  }
}

@media (max-width: 700px) {
  .requisites-footer {
    font-size: 0.4em;
  }
}

@media (max-width: 550px) {
  .footer-wrapper {
    height: 80px;
    margin-top: -80px;
  }

  .image-footer {
    height: 20px;
  }

  .payment-methods-text {
    font-size: 1rem;
  }

  .links-text {
    font-size: 0.7rem;
    font-weight: bolder;
  }
  .footer-wrapper {
    padding: 5px 2.5px;
  }

  .footer-content {
    padding: 10px;
  }
}
