.modal-action.modals {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
}

.modals-button {
  margin: 0 10px !important;
  width: 35% !important;
  text-transform: lowercase !important;
}

@media (max-width: 625px) {
  .btn.modals {
    width: 40% !important;
  }
}

@media (max-width: 550px) {
  .modals-button.mobile {
    display: none !important;
  }

  .modals-button {
    width: 100% !important;
  }
  .modal-action.modals {
    margin: 0 !important;
    padding: 5px !important;
  }
}
