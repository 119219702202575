.message-content {
    padding: 5px;
    max-width: 50%;
    border-radius: 10px;
    margin: 5px 0;
}

.message {
    display: flex;
    justify-content: flex-start;
}

.message.owner {
    display: flex;
    justify-content: flex-end;
}

.message .message-content {
    background: #CAFAAD
}

.message.owner .message-content {
    background: #FFD9C5;
}

@media(max-width: 550px) {
    .message-content {
        font-size: 8px;
    }
}