.card.payment-page {
  border-radius: 0.28571429rem;
  padding: 5px 10px;
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  position: relative;
  padding-left: 25px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

.close-payment-page {
  position: absolute;
  right: 10px;
  top: 10px;
}

.close-payment-page:hover {
  cursor: pointer;
}

.privat-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.payment-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 5px;
}

.payment-modal-actions {
  width: 100%;
  max-width: 100%;
}

.close-payments {
  display: block;
  color: black;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 0;
}

.modal-content.payments {
  padding: 0;
  margin-top: 15px !important;
  border-bottom: 1px solid lightgray;
  border-top: none;
}

.buttons-payments {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  /* justify-content: space-between !important; */
  gap: 8px !important;
  padding: 10px 0 !important;
}

.buttons-payments-modal {
  display: flex !important;
  justify-content: space-between !important;
  gap: 8px !important;
  padding: 10px 0 !important;
}

.modal-payments-btn {
  margin: 0 2.5px !important;
  width: 100% !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
  background-color: transparent !important;
  /* text-transform: lowercase !important; */
}

.modal-payments-btn.liqpay {
  /* background: url("../../assets/liqpay5.png") no-repeat 50% 50% / contain !important; */
}

.warning {
  text-align: left;
  font-size: 1em;
  color: brown;
}

.arrow-left {
  display: none !important;
  position: absolute !important;
  left: 15px !important;
  top: 3px !important;
}

.mobile-back {
  position: absolute !important;
  left: 15px !important;
  top: 0 !important;
  color: black !important;
  padding: 0 !important;
  height: 30px !important;
  background-color: rgba(255, 255, 255, 1) !important;
}

.payment-text-content {
  font-size: 1.3rem;
  font-weight: bold;
}

.payment-text-content div {
  margin: 10px 0;
}

@media (max-width: 625px) {
  .close-payment-page {
    right: 5px;
    top: 5px;
  }
  .modal-content.payments,
  .warning {
    font-size: 0.9em;
  }
  .modal-payments-btn {
    /* padding: 0 !important; */
    font-size: 0.8em !important;
    /* width: 50% !important; */
    height: 30px !important;
  }

  .buttons-payments-modal {
   padding: 0 !important;
   font-size: 0.8em !important;
   width: 100% !important;
   height: 30px !important;
  }

  /* .buttons-payments.privat {
    display: none;
  } */
  .payment-text-content {
    font-size: 0.9rem;
  }
  .payment-text-content div {
    margin: 5px 0;
  }

  .card.payment-page {
    margin-top: 10px;
  }

  .back {
    font-size: 0.8em;
  }
}

@media (max-width: 1366px) and (min-width: 600px) {
  .modal-content.payments {
    margin-bottom: 0;
  }
}

@media (max-width: 510px){
  .buttons-payments {
    grid-template-columns: repeat(2, 1fr);
  }

  .buttons-payments button:last-child{
    grid-row: 2;
    grid-column: 1/3;
  }
}



@media (max-width: 550px) {
  .card.payment-page {
    padding-left: 10px;
    padding-bottom: 0;
  }
}

