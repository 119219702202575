.sidenav.mobile-size .header-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 180px);
  justify-content: flex-start;
  background: rgba(0, 0, 0, 0);
}

.sidenav.mobile-size .header-navigation button {
  width: 80%;
  height: 35px;
  margin-bottom: 10px;
  font-size: 12px;
}

.sidenav.mobile-size {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 225px;
  padding: 20px 0;
}

.logo-sidenav {
  width: 50%;
}

.logo-sidenav img {
  max-width: 100%;
}

.btn.navigation.mobile {
  margin: 10% 0 !important;
}
