.input-field.liqpay {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    width: 100%;
  }
  
  #qr-pay {
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .liqpay-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .liqpay-submit {
    width: 100%;
  }
  
  .liqpay-submit .btn {
    border-radius: 7px;
    margin-bottom: 5px;
  }
  
  .button-liqpay {
    width: 45% !important;
    margin: 0 2.5px;
  }
  
  .button-liqpay i {
    margin-top: 1px !important;
  }
  
  @media (max-width: 550px) {
    .button-liqpay {
      width: 49% !important;
      margin: 0 1.5px !important;
    }
  }
  
  @media (max-width: 850px) {
    .button-liqpay {
      margin: 0 2% !important;
      font-size: 12px !important;
      padding: 10px !important;
    }
  }
  
  @media (max-width: 700px) {
    #qr-pay {
      width: 90%;
      font-size: 10px;
    }
    .btn.back {
      padding: 0;
    }
    .button-liqpay {
      margin: 0 2% !important;
      font-size: 12px !important;
      padding: 10px !important;
    }
  }
  