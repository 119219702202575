.archives-actions-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
}
.archives-nav-item .item {
  text-transform: none !important;
}

@media (max-width: 700px) {
  .archives-nav-item {
    height: 25px !important;
    padding: 0 !important;
    font-size: 10px !important;
  }
}
