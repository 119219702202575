.close-inspectors {
  position: absolute;
  top: 5px;
  right: 5px;
}

@media (max-width: 550px) {
  .close-inspectors {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
