.cabinet-wrapper {
  max-width: 1000px;
  box-sizing: border-box;
  margin: 0 auto;
  min-height: 100%;
  padding-bottom: 100px;
}

.no-contragent {
  font-size: 35px;
  font-weight: bold;
  color: #2e6195;
}

@media (max-width: 550px) {
  .cabinet-wrapper {
    padding-bottom: 75px;
  }
}
