.archive-card-btn {
  width: 100% !important;
  text-transform: lowercase !important;
  margin-top: 10px !important;
  margin: 0 !important;
  vertical-align: center !important;
}

.date-content-сard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.helper-text.date {
  font-size: 10px;
  color: #2e6195;
}

.date-content-сard .react-datepicker-popper {
  left: -35px !important;
}

.archive-contract-title.bills-archive {
  font-size: 30px;
}

@media (max-width: 650px) {
  .archive-contract-title.bills-archive {
    font-size: 20px;
    margin-top: 25px;
  }

  .archive-card-btn {
    font-size: 0.8em !important;
    height: 25px !important;
    padding: 0 !important;
  }
}

@media (max-width: 500px) {
  .archive-contract-title.bills-archive {
    font-size: 15px;
    margin-top: 40px;
  }
}
