.textarea {
    width: 100%;
    margin-bottom: 25px;
}

.textarea-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

.btn.textarea {
    width: 175px;
    background: #8FB9E6 ;
    border-radius: 7px;
    margin-bottom: 0;
}

.textarea-wrapper {
    width: 90%;
}