.settings-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  padding: 35px 5px;
  margin: 5px;
  align-items: center;
  background: white;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
