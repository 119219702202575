.textarea-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.textarea {
  width: 80%;
}

@media (max-width: 550px) {
  .materialize-textarea.revision {
    font-size: 10px !important;
  }
}
