.modal.objects-modal {
  padding: 10px 0;
  border-radius: 8px;
  overflow: hidden;
  max-width: 1500px !important;
  width: 100% !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.close-object-modal {
  border-radius: 7px;
}

.close-object-btn {
  margin-top: 10px !important;
  background: rgb(229, 57, 53) !important;
  color: white !important;
}

.modal .modal-footer .btn.waves-effect.waves-light.red.darken-1.close-object-modal {
  margin: 0;
  width: 30%;
}

.modal-footer.close-object-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-top: 6px;
  height: auto;
}

.object-modal-title {
  font-size: 1.5rem;
  font-weight: bolder;
  padding-bottom: 6px;
}

@media (max-width: 550px) {
  .modal .modal-footer .btn.waves-effect.waves-light.red.darken-1.close-object-modal {
    display: inline-block;
    height: 25px;
    line-height: 0;
    font-size: 8px;
  }
  .object-modal-title {
    font-size: 1rem;
  }

  .close-object-btn {
    display: none !important;
  }
}
