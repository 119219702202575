.header-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 5px;
}

.item-navigation.links {
  background: #26a69a !important;
}

.item-navigation.links.menu .active.item.links {
  background: rgba(0, 0, 0, 0.25) !important;
  font-weight: bolder;
}

.header-navigation .btn.navigation {
  width: 24%;
  border-radius: 7px;
}

@media (max-width: 955px) {
  .btn.navigation {
    font-size: 10px;
  }
}

@media (max-width: 725px) {
  .header-navigation {
    width: 100%;
  }
}

@media (max-width: 615px) {
  .btn.navigation {
    padding: 0;
  }

  .header-navigation {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .header-navigation-wrapper {
    display: none;
  }
}

@media (max-width: 545px) {
  .header-navigation .btn.navigation {
    width: 24%;
  }
}

@media (max-width: 400px) {
  .header-navigation .btn.navigation {
    font-size: 8px;
  }
}
