.modal {
  overflow-x: hidden;
}

.modal.registration-block {
  max-width: 75%;
  overflow-y: auto;
  padding: 30px;
}

.validate {
  max-width: 60%;
}

.modal.registration-block {
  border-radius: 0.28571429rem;
}

.input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 50px;
}

.modal-footer .modal-action .btn.waves-effect {
  display: inline-block;
  vertical-align: middle;
  margin-right: 50px;
  border-radius: 7px;
  text-transform: lowercase;
}

.modal-footer .modal-action .btn.waves-effect:hover {
  opacity: 0.8;
}

.modal-action.pin {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
}

.replay-send {
  margin-top: 15px;
}

.replay-send:hover {
  cursor: pointer;
}

.btn.pin {
  margin: 0 10px !important;
  width: 35% !important;
  color: white !important;
  text-transform: lowercase !important;
}

@media (max-width: 1366px) and (min-width: 600px) {
  .modal.registration-block {
    max-width: 70%;
    overflow-y: auto;
    padding: 5px;
  }

  .input-field {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .modal-content {
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .modal-footer .modal-action .btn.waves-effect {
    display: inline-block;
    vertical-align: middle;
    margin-right: 25px;
  }
}

@media (max-width: 545px) {
  .modal.registration-block {
    max-width: 100%;
    max-height: 100%;

    padding: 5px;
  }
  .registration-wrapper .modal .modal-wrapper {
    width: 100%;
    overflow-y: auto;
    padding: 5px;
  }

  .input-field {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .modal-content {
    margin-bottom: 0;
  }

  .modal .modal-content {
    padding-bottom: 0;
  }

  h4 {
    font-size: 15px;
  }
}

@media (max-width: 550px) {
  .modal-footer .modal-action {
    display: flex;
    justify-content: center;
  }
  .btn.pin.mobile {
    display: none !important;
  }

  .btn.pin {
    width: 100% !important;
  }
}

@media (max-height: 415px) {
  .modal .registration-modal {
    display: none;
  }
}
