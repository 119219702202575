.current-messages-wrapper {
    width: 100%;
    padding: 0 5px;
}

.messages-content {
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 15px 10px;
}

.new-message {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

