.new-claim {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
    width: 100%;
    background: white;
    border-radius: 10px;
}

.new-claim-wrapper {
    width: 100%;
    padding: 15px 5px;
}