.accordion-title .title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.accordion .content.active {
  padding: 0 14px 8px 14px !important;
}

.accordion-title .title-content span {
  flex-grow: 1;
  margin-right: 10px;
}

.accordion-title .title-content .button {
  white-space: nowrap;
}

.object-list-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.object-list {
  flex-grow: 1;
}

.responsible-persons-button {
  margin-left: auto;
}

.object-list .title,
.object-list .content {
  text-align: left;
}

.object-list .content p {
  line-height: normal;
  margin: 6px 0 !important;
}

.object-list .content p:first-child {
  margin-top: 0 !important;
}

.object-list .title {
  font-size: 16px;
  color: black !important;
}

.object-list {
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  margin: 0;
}

.btn.close-object {
  padding: 0;
  font-size: 15px;
  width: 100px;
  line-height: 0;
  margin: 5px 0;
  border-radius: 7px;
  text-transform: lowercase;
}

.collection .collection-item.objects {
  font-size: 20px;
}

@media (max-width: 615px) {
  .btn.close-object {
    height: 25px;
    line-height: 0;
  }

  .collection .collection-item.objects {
    font-size: 10px;
  }
}

.object-list__responsible {
  border-top: 1px solid rgba(128, 128, 128, 0.5);
  padding-top: 8px;
  margin-top: 16px;
}

.object-list__responsible__content {
  width: 97.7%;
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 32px;
  padding-bottom: 16px;
}

.table-wrapper {
  margin-bottom: 16px;
}

@media (max-width: 1260px) {
  .object-list__responsible__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.object-list__responsible__content__oneC table th:nth-child(1),
.object-list__responsible__content__oneC table td:nth-child(1) {
  width: 25%;
}

.object-list__responsible__content__oneC table th:nth-child(2),
.object-list__responsible__content__oneC table td:nth-child(2) {
  width: 25%;
}

.object-list__responsible__content__oneC table th:nth-child(3),
.object-list__responsible__content__oneC table td:nth-child(3) {
  width: 25%;
}

.object-list__responsible__content__oneC table th:nth-child(4),
.object-list__responsible__content__oneC table td:nth-child(4) {
  width: 20%;
}

.object-list__responsible__content__oneC table th:nth-child(5),
.object-list__responsible__content__oneC table td:nth-child(5) {
  width: auto;
  text-align: center;
}

@media (max-width: 768px) {
  .object-list__responsible__content__oneC table th:nth-child(5) {
    text-align: start;
  }
}

.object-list__responsible__content__pult table th:nth-child(1),
.object-list__responsible__content__pult table td:nth-child(1) {
  width: 60%;
}

.object-list__responsible__content__pult table th:nth-child(2),
.object-list__responsible__content__pult table td:nth-child(2) {
  width: 40%;
}

table td button {
  width: 24px;
  height: 24px;
}

table td button i {
  color: black;
}

.object-list__responsible__content__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.object-list__responsible__content__buttons button {
  margin: 0 !important;
}

.object-list__responsible__content table input {
  height: 2rem !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
}

.responsible-confirm-modal {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
