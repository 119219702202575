.collection.current-claims {
  max-height: 175px;
  overflow-y: auto;
  border-radius: 10px;
}

.text-claim-list {
  color: #2e6195;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.collection-claim-list-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 80%;
}

.collection .collection-item.active {
  background: #ddd;
  color: cornflowerblue;
}
.collection a.collection-item {
  color: black;
}

@media (max-width: 325px) {
  .collection .collection-item {
    font-size: 7px;
  }
  .collection-claim-list-wrapper {
    min-width: 90%;
  }
}
