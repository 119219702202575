.settings-button {
  text-transform: lowercase !important;
  width: 125px !important;
  height: 30px !important;
  font-size: 0.8rem !important;
}

.settings-button-mobile {
  display: none !important;
}

.settings-button.password {
  width: 150px !important;
}

/* @media (max-width: 750px) {
  .settings-button {
    height: 20px !important;
    font-size: 12px !important;
  }
} */

@media (max-width: 550px) {
  .settings-button {
    display: none !important;
  }
  .settings-button-mobile {
    display: block !important;
    margin-right: 50px !important;
  }
}
