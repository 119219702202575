.btn.pdf-button {
  width: 49% !important;
  margin: 0 !important;
  height: 39px !important;
  text-transform: lowercase !important;
}

@media (max-width: 715px) {
  .btn.pdf-button {
    width: 49% !important;
  }
}

@media (max-width: 615px) {
  .btn.pdf-button {
    height: 25px !important;
    padding: 0 !important;
  }
}
