* {
  box-sizing: border-box;
  word-wrap: break-word;
}

.modals {
  z-index: 1500 !important;
}

.modal-overlay {
  opacity: 0.9 !important;
}

.drag-target.right-aligned {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  line-height: normal !important;
}

.app {
  height: 100%;
  overflow-y: auto;
}

.card .card-content {
  margin: 0;
}

.btn {
  font-weight: bold;
}

@media (min-width: 550px) {
  .modal {
    padding: 0 15px;
  }
  .app {
    background: url("./assets/bg_step5.png") no-repeat 50% 50% / cover;
  }
}
