.archives-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.month-year-picker .caption {
  display: none !important;
}

.contracts-content-archives {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 49%;
  justify-content: space-between;
}

.date-content {
  display: flex;
  width: 49%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.month-year-picker {
  margin-top: 0 !important;
  width: 100% !important;
}

.month-year-picker .month-picker {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.month-year-picker .month-picker > div {
  display: flex !important;
  justify-content: center !important;
}

.month-year-picker .month-picker > div > div {
  width: 18% !important;
  height: 35px !important;
  margin: 5px !important;
  border-radius: 7px !important;
}

.month-year-picker .year-picker .controls {
  position: relative !important;
}

.month-year-picker .year-picker {
  margin-bottom: 5px !important;
  display: flex !important;
  justify-content: center !important;
}

.month-year-picker .year-picker span {
  display: none !important;
  font-size: 15px !important;
}

.month-year-picker .year-picker .controls .fa {
  font-size: 15px !important;
}

.date-title {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  color: #2e6195;
  text-align: center;
}

.archive-contract-title {
  font-size: 40px;
  text-align: center;
  margin-top: 35px;
  font-weight: bold;
  color: #2e6195;
}

.pdf-action {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn.date-open {
  display: none;
  text-transform: lowercase;
  width: 100%;
}

.datepicker-content {
  width: 100%;
}

@media (max-width: 800px) {
  .archive-contract-title {
    font-size: 35px;
  }
}

@media (max-width: 650px) {
  .archive-contract-title {
    font-size: 15px;
    margin-top: 0;
  }
  .datepicker-content {
    display: none;
  }

  .btn.date-open {
    padding: 0;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    line-height: 0;
  }
}

@media (max-width: 615px) {
  .btn.date-open {
    height: 25px;
  }

  .month-year-picker .month-picker > div > div {
    width: 25% !important;
    height: 35px !important;
    margin: 10px !important;
  }

  .month-year-picker .year-picker .controls .fa {
    font-size: 25px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
