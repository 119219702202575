.archives-bills-page-wrapper {
    padding: 0 5px;
}

.archives-bills-page-wrapper div input {
    text-align: center;
    font-weight: bold;
}

.archives-bills-page-wrapper div input:hover {
    cursor: pointer;
}

@media(max-width: 400px) {
    .archives-bills-page-wrapper div input {
        font-size: 10px !important;
    }

    .btn.archive-card {
        height: 25px;
        line-height: 0;
        padding: 0;
    }
}