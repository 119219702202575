.appeal-modal {
  max-width: 1200px !important;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.appeal-modal-header,
.appeal-modal-actions,
.appeal-modal-content {
  padding: 16px 0 !important;
}

.appeal-modal-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

@media (max-width: 480px) {
  .appeal-modal-header {
    font-size: 16px !important;
  }
}

.appeal-modal__responsible__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.appeals-modal-content .objects-list__responsible {
  border: 0 !important;
}

.appeals-modal-content .object .appeals-modal-content table tr {
  cursor: pointer;
}

.appeals-modal-actions button {
  margin: 0 !important;
}

.appeal-modal__close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  width: 16px;
  height: 16px;
}
