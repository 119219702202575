.btn.confirm-date {
  border-radius: 0.28571429rem !important;
  background: red !important;
  line-height: 0 !important;
  text-transform: lowercase !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 15px !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 30px !important;
  width: 50% !important;
}

.btn.confirm-date i {
  margin-top: 10px !important;
}

.modal-content-date {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.modal.modal-date {
  height: 300px;
  border-radius: 10px;
}
