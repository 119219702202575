.appeals-modal {
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.appeals-modal-header,
.appeals-modal-actions,
.appeals-modal-content {
  padding: 16px 0 !important;
}

.appeals-modal-header {
  padding-right: 0 !important;
}

@media (max-width: 480px) {
  .appeals-modal-header {
    font-size: 16px !important;
  }
}

.appeals-modal-content table tr {
  cursor: pointer;
}

.appeals-modal-actions button {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .appeals-modal-actions {
    display: none !important;
  }
}

.appeals-modal__close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
}
