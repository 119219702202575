.button-up {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background: rgba(255, 0, 0, 0.3)
}

@media(max-width: 1024px) {
    .btn-floating.btn-large.waves-effect.waves-light.button-up {
        display: flex;
        align-items: center;
        position: fixed;
        right: 5px;
        bottom: 5px;
        width: 30px;
        height: 30px;
        background: rgba(255, 0, 0, 0.3)
    }

    .btn-floating.btn-large.waves-effect.waves-light.button-up i{
        line-height: 35px;
    }
}