.current-clayms-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px;
    padding-top: 15px;
}

.current-clayms-content {
    display: flex;
    justify-content: center;
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 10px 0;
}