.archives-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
}

@media(max-width: 615px) {
    .archives-wrapper {
        padding: 0 5px;
    }
}
