.modal.archive-modal-bills {
  border-radius: 0.28571429rem;
  padding: 20px 0;
  padding-left: 10px;
  overflow-y: auto;
}

.total-bills {
  font-size: 20px;
  margin-top: 20px;
}

.archive-bills-button-content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
}

.modal.archive-modal-bills table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-close {
  color: white !important;
  background: #db2828 !important;
}

.modal.archive-modal-bills table thead {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.modal.archive-modal-bills tr {
  display: flex;
  padding: 0 10px;
  width: 100%;
  justify-content: space-between;
}

.modal.archive-modal-bills table tbody {
  display: flex;
  overflow-y: auto;
  max-height: 45vh;
  width: 100%;
  flex-direction: column;
}

div .centered thead .th-center,
div .centered thead .th-right {
  width: 30%;
  text-align: right;
  margin-right: 30px;
}

.centered tbody .table-content {
  width: 30%;
  text-align: right;
}

@media (max-width: 550px) {
  .modal.archive-modal-bills table tbody {
    max-height: 35vh;
    font-size: 10px;
  }
  .modal.archive-modal-bills {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .total-bills {
    font-size: 12px;
  }

  div .centered thead .th-center,
  div .centered thead .th-right {
    margin-right: 10px;
  }
}

@media (min-width: 995px) {
  .modal.archive-modal-bills {
    width: 750px;
  }
}
