.init-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #30809f;
  padding-top: 150px;
}

.init-page div {
  margin-top: 50px;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.init-page img {
  animation-duration: 3s;
  animation-name: logo;
}

@keyframes logo {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
