.header-content {
  display: flex;
  background: none;
  padding: 20px 0;
  background: #88e7c8;
  margin: 5px;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.btn.mobile-sidenav {
  display: none;
  box-shadow: none;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
}

.logo-wrapper {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100px;
}

.header-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 80%;
}

.collaps-content {
  width: 75%;
  position: relative;
  height: 50%;
}

.header-title {
  display: flex;
  justify-content: center;
  height: 1.5em;
  font-size: 25px;
  color: darkgreen;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .header-title {
    font-size: 20px;
  }

  .logo {
    width: 75px;
  }

  .header-center {
    width: 80%;
    margin-left: 5px;
  }

  .header-title h4 {
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
    font-size: 25px;
  }

  .collaps-content {
    width: 85%;
    position: relative;
    height: 50%;
  }
}

@media (max-width: 625px) {
  .header-title {
    font-size: 15px;
  }

  .logo {
    width: 75px;
  }
  .header-navigation {
    display: flex;
    justify-content: space-around;
    width: 300px;
    margin: 0 auto;
  }

  .header-navigation .btn.navigation {
    width: 100px;
    height: 25px;
    font-size: 10px;
    line-height: 0;
  }

  .header-navigation {
    margin-top: 20px;
  }

  .header-title {
    width: 100%;
  }

  .header-title h4 {
    text-align: center;
    font-size: 1rem;
  }

  .header-content {
    padding: 10px;
  }

  .collaps-content {
    width: 85%;
  }

  .header-center {
    margin: 0;
  }
}

@media (max-width: 615px) {
  .header-content {
    margin: 5px 2.5px;
  }

  .btn.mobile-sidenav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 40px;
    padding: 0;
    background: rgba(0, 0, 0, 0);
    margin-top: inherit;
  }

  .btn.mobile-sidenav i {
    margin: 0;
    line-height: 0;
    color: black;
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  .logo {
    width: 60px;
  }
}

@media (max-width: 370px) {
  .logo {
    width: 50px;
  }

  .logo-wrapper {
    width: 10%;
  }

  .header-center {
    width: 90%;
    margin-left: 0x;
  }
}
