.registration-wrapper {
  min-height: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

@media (max-height: 415px) {
  .registration-wrapper {
    padding-top: 2%;
  }
}
