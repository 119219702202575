.card-wrapper-contracts {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background: white;
  border-radius: 0.28571429rem;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.contracts-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 49.5%;
}

.payments-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contract-number {
  color: #2e6195;
  font-weight: 600;
  text-align: center;
}

.text-debt {
  font-size: 15px;
  font-weight: 600;
  margin: 5px 0;
}

.text-debt.description {
  font-size: 10px;
}

.summ-debt {
  font-weight: 600;
  margin: 5px 0;
  font-size: 25px;
}

.pdf-buttons {
  display: flex;
  justify-content: space-between;
}

.objects-action {
  width: 100%;
}

.objects-settings-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 768px) {
  .objects-settings-row {
    gap: 4px;
  }
}

.objects-settings-row button {
  margin: 0 !important;
  margin-top: 8px !important;
}

.btn.objects,
.objects-settings-btn {
  width: 100% !important;
  text-transform: lowercase !important;
  margin-top: 10px !important;
  color: white !important;
}

.btn.cost {
  width: 100% !important;
  text-transform: lowercase !important;
  color: white !important;
  margin-top: 10px !important;
  height: 39px !important;
}

.btn.cost:hover {
  background-color: rgb(11, 128, 0) !important;
}

.debt-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* height: 85px; */
  height: 100%;
}

.left-content {
  width: 100%;
}

@media (max-width: 625px) {
  .payments-action {
    display: flex;
    justify-content: flex-end;
  }

  .btn.objects,
  .btn.cost,
  .objects-settings-btn {
    height: 25px !important;
    padding: 0 !important;
  }
  .btn.cost.privat24 {
    width: 100%;
  }
  .text-debt.description {
    font-size: 5px;
  }
  .summ-debt {
    font-size: 15px;
  }

  .text-debt {
    font-size: 10px;
  }
  .btn.cost {
    padding: 0;
  }
}

@media (max-width: 550px) {
  .privat24,
  .btn.objects,
  .objects-settings-btn,
  .btn.pdf-button {
    font-size: 0.7em !important;
  }
}

@media (max-width: 405px) {
  .btn {
    font-size: 10px !important;
  }
}
