.modal-content {
  width: 100%;
  position: relative;
}

.modal-content.inspector {
  display: flex;
  flex-direction: column;
  align-items: center;
}
