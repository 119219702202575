.support-actions {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 15px;
}

.btn.support {
  border-radius: 7px;
  background: #7594ab;
  width: 20%;
}

.technical-work {
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 800px) {
  .btn.support {
    font-size: 10px;
  }
}

@media (max-width: 650px) {
  .btn.support {
    width: 30%;
  }
}

@media (max-width: 625px) {
  .btn.support {
    height: 25px;
    padding: 0;
    line-height: 0;
  }
}

@media (max-width: 400px) {
  .btn.support {
    font-size: 8px;
  }
}
