.btn.submit {
  border-radius: 7px;
}

@media (max-width: 550px) {
  .btn.submit {
    height: 25px;
    line-height: 0;
  }
}
