.emails-list {
  margin: 0;
}

.emails-list li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emails-list-text {
  max-width: 80%;
  font-size: 0.8em;
}

.delete-email:hover {
  cursor: pointer;
}
.emails-list {
  width: 100%;
}

.email-list-button-container {
  display: flex;
  justify-content: flex-start;
  margin: 0 !important;
}

.delete-email {
  text-transform: lowercase !important;
  height: 30px !important;
  font-size: 0.8rem !important;
}

.icon-delete-email {
  margin: 0 !important;
  display: none !important;
}

.email-list-item {
  padding-left: 2em;
  margin: 10px 0;
}

@media (max-width: 550px) {
  .delete-email {
    display: none !important;
  }

  .icon-delete-email {
    display: block !important;
    font-size: 0.85em !important;
  }

  .email-list-button-container {
    width: 40px;
    margin-right: 20px;
  }

  .btn.settings-button-mobile.email {
    margin-right: 0;
  }
}

@media (max-width: 400px) {
}
