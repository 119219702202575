.modal.revision-claim {
  height: auto;
  width: 45%;
  border-radius: 10px;
  padding: 10px;
}

.modal-footer-revision-claim {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1024px) {
  .modal.revision-claim {
    width: 90%;
    padding: 5px;
  }
}
