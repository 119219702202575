.settings-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 75%;
}

.settings-text {
  font-size: 1.5em;
  margin: 0;
  display: flex;
  align-items: center;
}

.column-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
}

.password-change {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}

.settings-button-box.support {
  margin: 0 auto;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .settings-text-wrapper {
    width: 95%;
  }
  .column-wrapper.settings-text {
    margin: 0;
  }
}

@media (max-width: 800px) {
  .settings-text-wrapper {
    width: 99%;
  }
}

@media (max-width: 750px) {
  .settings-text,
  .settings-text i {
    font-size: 20px;
  }
}

@media (max-width: 630px) {
  .settings-text,
  .settings-text i {
    font-size: 15px;
  }
}

@media (max-width: 505px) {
  .settings-text {
    font-size: 16px;
  }

  .no-email {
    font-size: 0.7em;
  }

  /* .settings-text span {
        display: block;
    } */
}
