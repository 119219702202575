.claim-select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.select-wrapper {
  width: 70%;
}

.text-claim-select {
  color: #2e6195;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.claim-inputs {
  margin: 25px 0;
  width: 100%;
}

.claim-inputs label {
  margin: 0 5px;
}

.select-wrapper label {
  top: 0;
}

.select-wrapper input.select-dropdown {
  font-size: 12px;
  color: black;
}

.select-wrapper ul li span {
  font-size: 12px;
  color: black;
}

.select-wrapper ul .selected span {
  background: lightblue;
}

@media (max-width: 650px) {
  .select-wrapper {
    width: 70%;
  }

  .claim-select-wrapper {
    width: 100%;
  }
}
