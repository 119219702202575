.modal.email {
  border-radius: 0.28571429rem;
  overflow-y: auto;
}

.modal-close-once.sender {
  background: #db2828 !important;
  color: white !important;
}

.modal-close.btn.waves-effect.waves-light.modal-close-mobile {
  background: none !important;
}

.modal-text-content {
  font-size: 1.5em;
  text-align: center;
}

@media (max-width: 550px) {
  .modal-text-content {
    font-size: 0.8em;
    width: 100% !important;
    padding: 0 15px;
    margin-top: 15px;
  }

  .modal.email .ui.button.modal-close-once.sender {
    display: none !important;
  }
}
