.modal .password-modal {
  padding: 15px;
  padding-bottom: 0;
}

.modal {
  overflow-y: auto;
}

.password-btn {
  width: 35%;
  color: white !important;
  margin: 0 10px !important;
  text-transform: lowercase !important;
}

.modal.modal.password-block,
.modal.password-block.once {
  border-radius: 0.28571429rem;
}

.btn.send-password,
.btn.waves-effect.modal-close {
  text-transform: lowercase;
  border-radius: 7px;
}

.btn.waves-effect.modal-close {
  margin-left: 50px;
}

.btn.waves-effect.modal-close-mobile {
  display: none;
  background: #fafafa;
  box-shadow: none;
}

.btn.waves-effect.modal-close-mobile i {
  color: black;
  font-weight: bold;
}

.modal-action.password-modal-actions {
  display: flex;
  justify-content: center;
  width: 100%;
  border: none !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  padding-bottom: 5px !important;
  padding-top: 10px !important;
}

.forgot {
  margin-top: 15px;
}

.forgot:hover {
  cursor: pointer;
}

.modal.password-block {
  height: auto;
  overflow-y: auto;
  padding: 5px;
}

.input-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80% !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.input-field input {
  width: 100% !important;
  max-width: 100% !important;
}

.modal-footer {
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px !important;
}

@media (max-width: 550px) {
  .btn.waves-effect.modal-close-mobile {
    display: block !important;
    position: absolute !important;
    width: 35px !important;
    height: 35px !important;
    padding: 0 !important;
    right: 10px !important;
    top: 0 !important;
  }

  .password-btn.mobile {
    display: none !important;
  }
  .password-btn {
    width: 100% !important;
  }

  .input-field {
    width: 100% !important;
  }

  .password-p {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .modal-content.password-modal {
    display: none !important;
  }
}
