.modal-footer.type-info .text-content {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal.type-info {
  border-radius: 0.28571429rem;
  overflow-y: auto;
}

.modal-content.type-info {
  margin-bottom: 0;
  padding: 5px;
}

.type-info-modal-actions {
  margin-top: 15px;
}

@media (max-width: 1293px) {
  .modal.type-info {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .modal.type-info {
    width: 80%;
  }
}

@media (max-width: 550px) {
  .text-content {
    margin-bottom: 10px;
  }
}

@media (max-width: 850px) {
  .modal-footer.type-info .text-content {
    flex-direction: column;
    width: 85%;
    text-align: center;
  }
  .text-bills {
    font-size: 10px;
  }
}
