.preloader-wrapper.small {
  width: 20px !important;
  height: 20px !important;
}

@media (max-width: 505px) {
  .preloader-wrapper.small {
    width: 15px !important;
    height: 15px !important;
  }

  .preloader-wrapper.small.active {
    margin-top: 15px !important;
  }

  .preloader-wrapper.small.active.change-amount {
    margin-top: 10px !important;
  }
}
