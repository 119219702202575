.clayms-messages-actions-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 15px;
    border-radius: 7px;
}

.btn.clayms-messages-actions {
    border-radius: 7px;
    background: #8FB9E6;
    width: 30%;
}

@media(max-width: 800px) {
    .btn.clayms-messages-actions {
        font-size: 10px;
    }
}

@media(max-width: 650px) {
    .btn.clayms-messages-actions {
        width: 33%;
    }
}

@media(max-width: 625px) {
    .btn.clayms-messages-actions {
        height: 25px;
        padding: 0;
        line-height: 0;
    }
}

@media(max-width: 400px) {
    .btn.clayms-messages-actions {
        font-size: 7px;
    }
}

@media(max-width: 315px) {
    .btn.clayms-messages-actions {
        font-size: 6px;
        font-weight: bolder;
    }
}