.modal-content.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  padding-top: 15px;
}

.about-us-title {
  font-size: 1.5rem;
  font-weight: bolder;
}

.modal.about-us {
  overflow-y: auto;
  border-radius: 0.28571429rem;
}

.modal.about-us div {
  text-align: justify;
}

.modal-footer.close-about-us {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-top: 6px;
  height: auto;
}

.modal .modal-footer .btn.waves-effect.waves-light.red.darken-1.close-about-us {
  margin: 0;
  width: 30%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.object-modal-title {
  font-size: 1.5rem;
  font-weight: bolder;
  padding-bottom: 6px;
}

.btn.waves-effect.waves-light.modal-close-mobile.modal-close.about-us-close {
  display: block !important;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 550px) {
  .modal
    .modal-footer
    .btn.waves-effect.waves-light.red.darken-1.close-about-us {
    display: inline-block;
    height: 25px;
    line-height: 0;
    font-size: 8px;
  }
  .about-us-title {
    font-size: 1rem;
  }
  .modal.about-us div {
    font-size: 0.7rem;
  }
}
