* {
  padding: 0;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 100px;
  max-width: 45%;
  height: auto;
  text-align: center;
  border-radius: 0.28571429rem;
}

.submit-card {
  text-transform: lowercase !important;
  color: white !important;
}

.card .card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 100%;
}

.card .card-content .card-title {
  margin-bottom: 100px;
}

.card-title {
  font-weight: bold;
  color: #2e6195;
}

.card .card-content .input-field .react-tel-input {
  display: flex;
  justify-content: space-between;
}

.card .card-content .input-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}

@media (max-width: 1150px) {
  .card {
    max-width: 50%;
    margin-top: 10%;
  }

  .card .card-content .card-title {
    margin-bottom: 30px;
  }

  .card .card-content .react-tel-input {
    max-width: 90%;
    margin: 0 auto;
  }

  .card .card-content .input-field {
    max-width: 90%;
  }

  .card .card-content .form-control {
    max-width: 90%;
  }
}

@media (max-width: 750px) {
  .card {
    max-width: 80%;
    margin-top: 10%;
    height: 65%;
  }

  .card .card-action {
    padding: 0;
  }

  .card .card-content .card-title {
    margin-bottom: 5%;
    margin-top: 0;
    font-size: 25px;
  }

  .card .card-content .react-tel-input {
    max-width: 90%;
  }

  .card .card-content .input-field {
    max-width: 90%;
  }

  .card .card-content .form-control {
    max-width: 80%;
  }
}

@media (max-width: 550px) {
  .card .card-action {
    padding: 10px 0;
  }

  .card .card-content .card-title {
    margin-bottom: 5%;
    margin-top: 0;
    font-size: 15px;
  }

  .card .card-content .react-tel-input {
    max-width: 90%;
  }

  .card .card-content .input-field {
    max-width: 90%;
  }

  .card .card-content .form-control {
    max-width: 80%;
  }

  .form-control {
    font-size: 0.8em !important;
  }

  .submit-card {
    width: 75% !important;
  }
}

@media (max-height: 150px) {
  .card .card-content .card-title {
    display: none;
  }
}

@media (max-height: 75px) {
  .card .card-action {
    display: none;
  }
}
