.dv-star-rating {
    display: flex !important;
    flex-direction: row-reverse  !important;
    justify-content: center !important;
    width: 100% !important;
}

.dv-star-rating label {
    font-size: 50px;
}

.modal-content.modal-rating h4 {
    text-align: center;
    font-weight: bold;
    color: #2E6195;
}

.modal.modal-rating {
    height: 300px;
    width: 650px;
    border-radius: 10px;
}

.modal-footer.modal-rating{
    display: flex;
    justify-content: center
}

.modal-action.modal-rating .btn {
    width: 150px;
}

@media(max-width: 775px) {
    .modal-content.modal-rating h4 {
        font-size: 25px;
    }
}

@media(max-width: 625px) {
    .modal-content.modal-rating h4 {
        font-size: 20px;
    }
}


@media(max-width: 700px) {
    .modal.modal-rating {
        height: 300px;
        width: 650px;
        border-radius: 10px;
    }

    .modal-action.modal-rating .btn {
        width: 100px;
        padding: 0;
    }
}

@media(max-width: 590px) {
    .modal.modal-rating {
        height: 325px;
        width: 650px;
        border-radius: 10px;
    }

    .modal-action.modal-rating .btn {
        width: 100px;
        padding: 0;
    }
}

@media(max-width: 550px) {
    .modal.modal-rating {
        height: 275px;
        max-width: 95%;
        border-radius: 10px;
    }

    .modal-action.modal-rating .btn {
        width: 100px;
        padding: 0;
    }
}

@media(max-width: 550px) {
    .modal.modal-rating {
        height: 215px;
        max-width: 95%;
        border-radius: 10px;
    }
}

@media(max-width: 435px) {
    .modal-content.modal-rating h4 {
        font-size: 10px;
    }
}
