.qr-content {
  max-width: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
}

.qr-content p {
  font-size: 16px;
  font-weight: 700px;
}
