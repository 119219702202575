.error-page-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: url('../../assets/bg_step5.png') no-repeat 50% 50% / cover;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.error-page-wrapper img {
    max-width: 50%;
}

.error-content {
    text-align: center;
}