.contracts-col:hover {
  background: lightblue;
  cursor: pointer;
}

.collection-item {
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.collapsible {
  text-align: left;
  position: absolute;
  width: 100%;
  background: white;
  z-index: 2;
  color: darkgreen;
  border-radius: 7px;
}

.collapsible li {
  border-radius: 7px;
}

.collapsible-header {
  position: relative;
  padding: 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;
}

.collapsible-body {
  padding: 5px;
}

.material-icons.right.collaps {
  position: absolute;
  right: 0em;
}

.collapsible-header.select-collaps {
  background: rgb(233, 247, 233);
  font-weight: 600;
}

@media (max-width: 1024px) {
  .collapsible {
    width: 100%;
  }

  .collapsible-header {
    padding: 5px;
  }
}

@media (max-width: 625px) {
  .collapsible-header.select-collaps,
  .collapsible-header {
    font-size: 8px;
    display: flex;
    align-items: center;
    height: 20px;
  }

  .material-icons.right.collaps {
    margin: 0;
  }

  .collection .collection-item {
    font-size: 8px;
  }

  .collapsible {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 100%;
  }

  .collapsible-header.select-collaps {
    padding: 5px;
  }

  .collapsible-header {
    padding: 5px;
  }
}

@media (max-width: 450px) {
  .collapsible-header.select-collaps .material-icons.people {
    display: none;
  }
}
