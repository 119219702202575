.btn.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  padding: 0;
}

.copy-to-buffer input,
.copy-to-buffer textarea {
  color: black !important;
}

.requisites-block {
  margin: 10px 0;
}

.btn.copy i {
  line-height: 0;
  font-size: 1em;
}

.title-description {
  text-align: left;
  font-weight: bold;
}

.copy-to-buffer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input,
.textarea {
  width: 93% !important;
  height: auto !important;
  margin: 0 !important;
}

.icon-copy:hover {
  cursor: pointer;
  color: black !important;
}

@media (max-width: 625px) {
  .input {
    font-size: 0.9em !important;
  }
  .textarea {
    font-size: 0.55em !important;
  }
  .btn.copy {
    width: 25px;
    height: 20px;
  }

  .btn.copy i {
    font-size: 0.8em;
  }

  .title-description {
    font-size: 0.8em;
  }
}
